import Services from '../../../services'

class KelompokAnggaranAPi {
  get() {
    return Services.get('/ang/kelompok_anggaran')
  }

  getPage(page) {
    return Services.get('/ang/kelompok_anggaran/page/?per_page=10&page=' + page)
  }

  getKode() {
    return Services.get('/ang/kelompok_anggaran/no_baru')
  }

  create(value) {
    return Services.post('/ang/kelompok_anggaran', value)
  }

  update(value) {
    return Services.put('/ang/kelompok_anggaran', value)
  }
  
  delete(value) {
    return Services.post('/ang/kelompok_anggaran/delete', value)
  }
}

export default new KelompokAnggaranAPi()
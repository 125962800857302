import React, { useState, useEffect } from "react";
import { ButtonGroup, Modal } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Formik } from "formik";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
  ApprovalStatusButton,
} from "components";
import {
  InputSearch,
  DataStatus,
  Switch,
  ButtonCreate,
  ButtonDetail,
  ButtonUpdate,
  ButtonCancel,
  ButtonFilter,
} from "components2";
import { tableNumber, dateConvert } from "utilities2";
import { useModalConfirm } from "hooks2";
import {
  CatatanApprovalPenerimaPettyCash,
  ModalFilter,
  PenerimaPettyCashContent,
} from "./__PenerimaPettyCashComps__";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
  STATUS_APPROVAL,
  MODAL_TITLE,
} from "./__PenerimaPettyCashUtils__";
import PenerimaPettyCashApi from "./__PenerimaPettyCashApi__";

export const PenerimaPettyCashList = ({ setNavbarTitle }) => {
  const queryClient = useQueryClient();
  const modalConfirm = useModalConfirm();
  const [pagination, setPagination] = useState({
    q: "",
    page: 1,
    per_page: 10,
  });

  const [filter, setFilter] = useState({
    tgl_penerima_petty_cash_start: undefined,
    tgl_penerima_petty_cash_end: undefined,
    id_pekerja: undefined,
    id_unit_produksi: undefined,
    status_approval: undefined,
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);

  const [modal, setModal] = useState({
    type: "CREATE", // CREATE | UPDATE | DETAIL
    show: false,
    data: {},
  });

  const getPenerimaPettyCash = useQuery(
    ["penerima_petty_cash", "list", pagination, filter],
    () => PenerimaPettyCashApi.getList({ ...pagination, ...filter })
  );

  const createPenerimaPettyCash = useMutation((data) =>
    PenerimaPettyCashApi.create(data)
  );

  const updatePenerimaPettyCash = useMutation((data) =>
    PenerimaPettyCashApi.update(data)
  );

  const toggleStatusPenerima = useMutation(
    ({ active, data }) =>
      PenerimaPettyCashApi.showHide({
        id_penerima_petty_cash: data.id_penerima_petty_cash,
        status_penerima_petty_cash: active,
      }),
    {
      onMutate: ({ active, index, data }) => {
        const newData = getPenerimaPettyCash?.data?.data ?? [];
        newData[index] = { ...data, status_penerima_petty_cash: active };

        const finalData = { ...getPenerimaPettyCash.data, data: newData };

        queryClient.setQueryData(["penerima_petty_cash", "list", pagination], {
          ...finalData,
        });
      },
      onError: (err, { active, index, data }) => {
        const newData = getPenerimaPettyCash?.data?.data ?? [];
        newData[index] = { ...data, status_penerima_petty_cash: !active };

        const finalData = { ...getPenerimaPettyCash.data, data: newData };

        queryClient.setQueryData(
          ["penerima_petty_cash", "list", pagination],
          finalData
        );
      },
    }
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);
  const onCreateButtonClickHandler = () =>
    setModal({ show: true, type: "CREATE" });

  const onDetailButtonClickHandler = (data) =>
    setModal({ show: true, type: "DETAIL", data });

  const onUpdateButtonClickHandler = (data) =>
    setModal({ show: true, type: "UPDATE", data });

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  console.log(pagination);

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    await modalConfirm.trigger({
      size: "md",
      type: modal.type === "CREATE" ? "create" : "update",
      component: (
        <Formik initialValues={values}>
          <PenerimaPettyCashContent action="DETAIL" />
        </Formik>
      ),
      onSubmit: async () => {
        modalConfirm.loading();

        if (modal.type === "CREATE") {
          return await createPenerimaPettyCash
            .mutateAsync(formSubmitValueMapper(values), resetForm)
            .then(() => {
              modalConfirm.infoSuccess({
                title: "Data Penerima Petty Cash Berhasil Ditambah",
                customTextInfoDetail: "Data telah tersimpan ke database",
                size: "md",
                onHide: () => {
                  modalConfirm.close();
                  dismissModal(resetForm);
                },
              });
              getPenerimaPettyCash.refetch();
            })
            .catch((err) =>
              modalConfirm.infoError({
                title: "Data Penerima Petty Cash Gagal Ditambah",
                customTextInfoTitle: "Penyebab Kegagalan:",
                customTextInfoDetail: err?.response?.data?.message,
              })
            );
        }

        await updatePenerimaPettyCash
          .mutateAsync(formSubmitValueMapper(values))
          .then(() => {
            modalConfirm.infoSuccess({
              title: "Data Penerima Petty Cash Berhasil Diubah",
              customTextInfoDetail: "Data telah tersimpan ke database",
              size: "md",
              onHide: () => {
                modalConfirm.close();
                dismissModal(resetForm);
              },
            });
            getPenerimaPettyCash.refetch();
          })
          .catch((err) =>
            modalConfirm.infoError({
              title: "Data Penerima Petty Cash Gagal Diubah",
              customTextInfoTitle: "Penyebab Kegagalan:",
              customTextInfoDetail: err?.response?.data?.message,
            })
          );
      },
      onHide: () => modalConfirm.close(),
    });
  };

  const dismissModal = (resetForm) => {
    resetForm();
    setModal({ data: null, show: false, type: "" });
  };

  const formInitialValuesFilter = {
    tgl_penerima_petty_cash_start: filter?.tgl_penerima_petty_cash_start,
    tgl_penerima_petty_cash_end: filter?.tgl_penerima_petty_cash_end,
    id_pekerja: filter?.id_pekerja,
    id_unit_produksi: filter?.id_unit_produksi,
    status_approval: filter?.status_approval,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => setNavbarTitle("Penerima Petty Cash"), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch
              onChange={debounce(searchHandler, 1500)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate size="md" icon onClick={onCreateButtonClickHandler} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPenerimaPettyCash.isLoading || getPenerimaPettyCash.isError ? (
        <DataStatus
          loading={getPenerimaPettyCash.isLoading}
          text={
            getPenerimaPettyCash.isLoading
              ? "Memuat . . ."
              : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Penerima Petty Cash
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Informasi Penerima Petty <br /> Cash
                </Th>
                <Th style={{ minWidth: 100 }} className="p-1">
                  No. Pekerja
                </Th>
                <Th style={{ minWidth: 150 }} className="p-1">
                  Nama Pekerja
                </Th>
                <Th style={{ minWidth: 150 }} className="p-1">
                  Unit Produksi
                </Th>
                <Th style={{ minWidth: 150 }} className="p-1">
                  Keterangan
                </Th>
                <Th style={{ width: 10 }} className="p-1">
                  Status Approval
                </Th>
              </Tr>
            </THead>
            <TBody>
              {getPenerimaPettyCash?.data?.data?.length > 0 ? (
                getPenerimaPettyCash?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index,
                      })}
                    </TdFixed>
                    <TdFixed>
                      <div className="d-flex">
                        <ButtonGroup>
                          <ButtonDetail
                            variant="primary"
                            icon
                            noText
                            onClick={() => onDetailButtonClickHandler(e)}
                          />
                          {e.status_approval === "REV" && (
                            <ButtonUpdate
                              variant="success"
                              icon
                              noText
                              onClick={() => onUpdateButtonClickHandler(e)}
                            />
                          )}
                        </ButtonGroup>
                        <Switch
                          tooltip
                          size="sm"
                          className="mr-1"
                          checked={e?.status_penerima_petty_cash}
                          disabled={!(e.status_approval === "APP")}
                          onChange={(value) =>
                            toggleStatusPenerima.mutate({
                              active: value,
                              index: index,
                              data: e,
                            })
                          }
                        />
                      </div>
                    </TdFixed>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_penerima_petty_cash)
                        )}
                      </div>
                      <div>{e.no_penerima_petty_cash}</div>
                    </Td>
                    <Td>{e.no_karyawan ?? ""}</Td>
                    <Td>
                      <div>{e.nama_karyawan}</div>
                      <div>{e.nama_jabatan}</div>
                    </Td>
                    <Td>{e.nama_unit_produksi}</Td>
                    <Td>{e.keterangan}</Td>
                    <Td>
                      <ApprovalStatusButton
                        variant={
                          STATUS_APPROVAL[e.status_approval ?? "PEN"]?.variant
                        }
                      >
                        {STATUS_APPROVAL[e.status_approval ?? "PEN"]?.label}
                      </ApprovalStatusButton>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={pagination?.per_page}
            dataNumber={
              pagination?.page * pagination?.per_page - pagination?.per_page + 1
            }
            dataPage={
              pagination?.dataCount < pagination?.per_page
                ? pagination?.dataCount
                : pagination?.page * pagination?.per_page
            }
            dataCount={getPenerimaPettyCash?.data?.data_count}
            currentPage={pagination?.page}
            totalPage={getPenerimaPettyCash?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {/* Modal Create | Update | Detail */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal?.data, modal?.type)}
        validationSchema={formValidationSchema}
        onSubmit={onSubmitForm}
      >
        {(formik) => (
          <Modal
            show={modal.show}
            size={modal.type === "CREATE" ? "md" : "lg"}
            onHide={() => dismissModal(formik.resetForm)}
          >
            <Modal.Header closeButton>
              <span>{MODAL_TITLE[modal.type]} Penerima Petty Cash</span>
            </Modal.Header>
            <Modal.Body>
              <PenerimaPettyCashContent action={modal.type} />

              {modal.type !== "CREATE" && <CatatanApprovalPenerimaPettyCash />}
            </Modal.Body>

            <Modal.Footer>
              <div className="d-flex justify-content-center">
                <ButtonCancel
                  className="mr-2"
                  disabled={formik.isSubmitting}
                  onClick={() => dismissModal(formik.resetForm)}
                />

                {modal.type === "CREATE" && (
                  <ButtonCreate
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    onClick={() => formik.handleSubmit()}
                  />
                )}

                {modal.type === "UPDATE" && (
                  <ButtonUpdate
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    onClick={() => formik.handleSubmit()}
                  />
                )}
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter
            data={filter}
            setData={setFilter}
            toggle={onFilterButtonClickkHandler}
          />
        </Formik>
      )}
    </CRUDLayout>
  );
};

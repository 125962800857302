import React, { Fragment } from "react";
import { useQuery } from "react-query";
import { Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, Select, TextArea } from "components2";
import { DatePicker } from "components";
import { dateConvert } from "utilities2";
import PenerimaPettyCashApi from "../__PenerimaPettyCashApi__";

export const PenerimaPettyCashContent = ({ action }) => {
  const { values, errors, touched, setValues, handleChange } =
    useFormikContext();

  const { data: karyawan, isLoading: isLoadingKaryawan } = useQuery(
    ["karyawan", "dropdown"],
    () => PenerimaPettyCashApi.getDropdownKaryawan()
  );

  const { data: unitProduksi, isLoading: isLoadingUnitProduksi } = useQuery(
    ["unitProduksi", "dropdown"],
    () => PenerimaPettyCashApi.getDropdownUnitProduksi()
  );

  const { isLoading: isLoadingGenerateNumber } = useQuery(
    ["generateNoPettyCash", values?.tgl_penerima_petty_cash],
    () =>
      PenerimaPettyCashApi.generateNumber({
        tanggal: values?.tgl_penerima_petty_cash,
      })
        .then((res) =>
          setValues((prev) => ({
            ...prev,
            no_penerima_petty_cash: res.data.data ?? "",
          }))
        )
        .catch(() =>
          window.alert("Tidak dapat memuat Nomor Penerima Petty Cash")
        ),
    {
      enabled: action === "CREATE" && !!values?.tgl_penerima_petty_cash,
    }
  );

  if (action === "DETAIL") {
    return (
      <Fragment>
        <Row>
          <Col>
            <InfoItemVertical
              label="Tgl. Penerima Petty Cash"
              text={dateConvert().getSlashDMY(
                new Date(values?.tgl_penerima_petty_cash)
              )}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="No. Penerima Petty Cash"
              text={values?.no_penerima_petty_cash}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical label="Pekerja" text={values?.nama_karyawan} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="Jabatan"
              text={values?.nama_jabatan_penerima}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="Unit Organisasi"
              text={values?.nama_unit_organisasi}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical
              label="Unit Produksi"
              text={values?.nama_unit_produksi}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical label="Keterangan" text={values?.keterangan} />
          </Col>
        </Row>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <DatePicker
            label="Tgl. Penerima Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_penerima_petty_cash
                ? new Date(values.tgl_penerima_petty_cash)
                : ""
            }
            onChange={(date) => {
              setValues({ ...values, tgl_penerima_petty_cash: date });
            }}
            error={
              errors.tgl_penerima_petty_cash &&
              touched.tgl_penerima_petty_cash &&
              true
            }
            errorText={errors.tgl_penerima_petty_cash}
          />
        </Col>

        <Col>
          <Input
            disabled
            label="No. Penerima Petty Cash"
            className="d-flex flex-column"
            loading={isLoadingGenerateNumber}
            value={values.no_penerima_petty_cash}
          />
        </Col>
      </Row>

      <Select
        label="Pekerja"
        placeholder="Pilih Pekerja"
        name="id_pekerja"
        onChange={(val) =>
          setValues({
            ...values,
            id_pekerja: val.value,
            nama_karyawan: val.nama_karyawan,
            nama_jabatan_penerima: val.nama_jabatan_penerima,
            nama_unit_organisasi: val.nama_unit_organisasi,
          })
        }
        defaultValue={
          karyawan?.find(({ value }) => value === values.id_pekerja) ?? null
        }
        options={karyawan ?? []}
        loading={isLoadingKaryawan}
        error={errors.id_pekerja && touched.id_pekerja && true}
        errorText={touched.id_pekerja && errors.id_pekerja}
      />

      <Select
        label="Unit Produksi"
        placeholder="Pilih Unit Produksi"
        name="id_unit_produksi"
        onChange={(val) =>
          setValues({
            ...values,
            id_unit_produksi: val.value,
            nama_unit_produksi: val.label,
          })
        }
        defaultValue={
          unitProduksi?.find(
            ({ value }) => value === values.id_unit_produksi
          ) ?? null
        }
        options={unitProduksi ?? []}
        loading={isLoadingUnitProduksi}
        error={errors.id_unit_produksi && touched.id_unit_produksi && true}
        errorText={touched.id_unit_produksi && errors.id_unit_produksi}
      />

      <TextArea
        label="Keterangan"
        placeholder="Masukkan Keterangan"
        name="keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan) && errors.keterangan}
      />
    </Fragment>
  );
};

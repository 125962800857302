// React
import React from "react";

// Form
import { Formik, useFormikContext } from "formik";

// Components
import { DatePicker, FilterModal } from "components";
import { Select } from "components2";

// API
import { useQuery } from "react-query";

import { RegistrasiKaryawanApi } from "api";

export const ModalFilter = ({ toggle }) => {
  const { values, handleSubmit, setValues } = useFormikContext();
  const { data: jabatan, isFetching: loadingJabatan } = useQuery(
    ["jabatan", "dropdown"],
    () => RegistrasiKaryawanApi.getDropdownJabatan()
  );

  const { data: unitOrganisasi, isFetching: loadingUnitOrganisasi } = useQuery(
    ["unitOrganisasi", "dropdown"],
    () => RegistrasiKaryawanApi.getDropdownUnitOrganisasi()
  );

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        id_jabatan: undefined,
        id_unit_organisasi: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <Select
        label="Jabatan"
        placeholder="Pilih jabatan"
        name="id_jabatan"
        options={jabatan ?? []}
        loading={loadingJabatan}
        onChange={async (val) =>
          setValues({
            ...values,
            id_jabatan: val.value,
          })
        }
        defaultValue={jabatan?.find((val) => val.value === values.id_jabatan)}
      />

      <Select
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        name="id_unit_organisasi"
        options={unitOrganisasi ?? []}
        loading={loadingUnitOrganisasi}
        onChange={async (val) =>
          setValues({
            ...values,
            id_unit_organisasi: val.value,
          })
        }
        defaultValue={unitOrganisasi?.find(
          (val) => val.value === values.id_unit_organisasi
        )}
      />
    </FilterModal>
  );
};

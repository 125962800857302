import Services from '../../../services'

class KategoriAnggaranAPi {
  get() {
    return Services.get('/ang/kategori_sumber_daya')
  }

  getPage(page) {
    return Services.get('/ang/kategori_sumber_daya/page/?per_page=10&page=' + page)
  }

  create(value) {
    return Services.post('/ang/kategori_sumber_daya', value)
  }

  update(value) {
    return Services.put('/ang/kategori_sumber_daya', value)
  }
  
  delete(value) {
    return Services.post('/ang/kategori_sumber_daya/delete', value)
  }
}

export default new KategoriAnggaranAPi()
import React from "react";
import Routes from "routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthContextProvider } from "utilities";
import { ModalConfirmProvider } from "hooks2";
import "styles/css/App.css";
import "react-datepicker/dist/react-datepicker.css";

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <ModalConfirmProvider>
          <Routes />
        </ModalConfirmProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  );
};

export default App;
